.actual-stocks {
    display: flex;
    margin: 100px 150px 200px;
    padding: 0;
    flex-direction: column;
}

.actual-stocks__title {
    margin: 57px auto 0 auto;
    padding: 0;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 37.5% */
    letter-spacing: 1.12px;
    max-width: 433px;
    width: 100%;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
}

.actual-stocks__content {
    margin: 0 auto;
    padding: 0;
    display: flex;
    max-width: 940px;
    width: 100%;
}

.actual-stocks__video {
    margin: 0;
    padding: 0;
    width: 320px;
    height: 667px;
    z-index: 3;
}

.actual-stocks__example {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.actual-stocks__text {
    margin: 45px 0 0 10px;
    padding: 0;
    max-width: 600px;
    width: 100%;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 31px; /* 100% */
    letter-spacing: 1.12px;
    text-align: justify;
}

.actual-stocks__name {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 25px;
}

.actual-stocks__images {
    margin: 0 10px 0 0;
    padding: 0;
}

.actual-stocks__undertaker {
    font-size: 18px;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 758px){

    .actual-stocks {
        margin: 200px 0 0 0;
    }

    .actual-stocks__name {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .actual-stocks__title {
        font-size: 36px;
    }

    .actual-stocks__content {
        flex-direction: column-reverse;
        max-width: 320px;
    }

    .actual-stocks__text {
        margin: 0;
        padding: 0;
        text-align: justify;
        font-size: 17px;
    }
}