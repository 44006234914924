.services__title {
    margin: 0 auto;
    padding: 50px 0 0 0;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services__title {
        font-size: 30px;
    }
}