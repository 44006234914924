.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.return {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 243, 240, 0.87);
  max-width: 180px;
  width: 100%;
  font-size: 25px;
  height: 70px;
  font-family: Montserrat, sans-serif;
  top: 90%;
  right: 1vw;
  position: fixed;
  border-radius: 160px;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  z-index: 2;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.12px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
  .return {
    max-width: 120px;
    font-size: 16px;
    top: 93%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
