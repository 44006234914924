.header__list {
    background: none;
    max-width: 758px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    color: #000;
    text-decoration: none;
    border: none;
}

.list {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-around;
}

.form {
    width: 100%;
    border: none;
    text-decoration: none;
    box-shadow: none;
    display: flex;
    text-align: center;
}

.header__list .active {
    color: #C3ADAD;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .list {
        max-width: 200px;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        justify-content: space-evenly;
        margin-left: 10px;
    }
    .hidden {
        display: block;
    }
}