.info__button_theme {
    margin: 0;
    padding: 0;
    background: none;
    border-bottom: 2px solid cornflowerblue;
    text-align: left;
}

.info__button-cursor {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .info__button_theme {
        z-index: 2;
        max-width: 320px;
        width: 100%;
        max-height: 320px;
        background: #EED0BE url("../../../../images/image-24-08-23-04-11-3.jpeg") no-repeat;
        opacity: 0.8;
        background-size: 320px 400px;
        object-fit: contain;
    }

    .info__button-cursor {
        display: block;
        position: relative;
        max-width: 60px;
        bottom: 120px;
        left: 23px;
    }
}