.map__text_undertaker {
    margin: 0;
    box-sizing:border-box;
    text-decoration:none;
    color:#b3b3b3;
    font-size:10px;
    font-family:YS Text,sans-serif;
    padding:0 20px;
    position:absolute;
    bottom:8px;
    width:100%;
    text-align:center;
    left:0;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    max-height:14px;
    white-space:nowrap;
    padding:0 16px;
    box-sizing:border-box;
    max-width: 320px;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .map__text_undertaker {
        padding: 0;
        margin: 0;
    }
}