.footer__logo {
    
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .footer__logo {
        margin: 40px 0 0 0;
        width: 234px;
        height: 98px;
    }
}