.footer__text {
    display: flex;
    flex-direction: column;
    color: #333333;
    font-family: "Montserrat-Light", Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 300;
    left: 0;
    letter-spacing: 1.05px;
    line-height: 19px;
    text-align: center;
    max-width: 300px;
    width: 100%;
}