.examples__images {
    border-radius: 40px;
    max-width: 400px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}