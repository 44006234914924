.body {
    margin: 0 auto;
    padding: 0;
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background: rgba(255, 243, 240, 0.87) url("../images/Pattern.svg") no-repeat;
    background-size: cover;
}

@media screen and (min-width: 501px) and (max-width: 758px){
    .body {
        justify-content: center;
        background-image: url("../images/Pattern 2.svg");
    }
}

@media screen and (min-width: 320px) and (max-width: 500px){
    .body {
        justify-content: center;
        background-image: url("../images/Pattern 3.svg");
    }
}