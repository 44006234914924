.info__main_number {
    display: flex;
    flex-direction: column;
}

.info__main-number {
    text-decoration: none;
    color: #3C3C3C;
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 115.385% */
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__main-number {
        margin: 8px auto 8px auto;
        font-size: 19px;
    }
}