.services__list_element {
    margin: 0 110px 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 50% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

.services__list_element:nth-child(n+2) {
    margin-top: 40px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services__list_element {
        color: #3C3C3C;
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 100% */
        letter-spacing: 1.12px;
        text-transform: uppercase;
    }
}
