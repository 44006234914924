.map__iframe_undertaker {
    max-width: 477px;
    width:100%;
    height:100%;
    border:1px solid #e6e6e6;
    border-radius:8px;
    box-sizing:border-box
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .map__iframe_undertaker {
        max-width: 320px;
    }
}