.examples {
    z-index: 6;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    visibility: hidden;
    transition: all 0.8s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .examples {
    }
}
