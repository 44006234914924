.map__icons {
    max-width: 356px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .map__icons {
        margin-top: 30px;
    }
}