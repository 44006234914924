.stock {
    max-width: 1200px;
    width: 100%;
    margin: 70px 0 110px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stock__title {
    margin: 121px 0 0 0;
    padding: 0;
    color: #000;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 96px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 800px;
}

.stock__address {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.stock__info {
    margin: 8px 0 0 0;
    padding: 0;
    display: flex;
    max-width: 580px;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid black;
}

.stock__time {
    margin: 5px 0 0 0;
    width: 50%;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.stock__times {
    margin: 0;
    padding: 0;
    font-size: 38px;
}

.stock__text {
    margin: 0;
    padding: 0;
    font-size: 28px;
}

.stock__numbers {
    border-left: 1px solid black;
    margin: 0;
    padding: 0;
    width: 50%;
    text-transform: uppercase;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.stock__number {
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 83.333% */
    letter-spacing: 1.12px;
    margin: 17px 0 0 0;
    padding: 0;
    text-decoration: none;
}

.stock__number:hover {
    cursor: pointer;
}

.stock__buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    max-width: 580px;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .stock {
        max-width: 320px;
        margin-top: 120px;
        margin-bottom: 55px;
    }

    .stock__title {
        font-size: 39px;
    }

    .stock__address {
        font-size: 17px;
        letter-spacing: 0.19px;
    }

    .stock__times {
        margin-left: 10px;
        font-size: 20px;
    }

    .stock__text {
        margin-left: 10px;
        font-size: 16px;
    }

    .stock__number {
        font-size: 14px;
        margin-top: 4px;
        margin-right: 5px;
    }

    .stock__buttons {
        max-width: 320px;
    }
}