.services__image {
    max-width: 32px;
    width: 100%;
    max-height: 40px;
    margin: 0 0 0 0;
    padding: 0;
}

@media screen and (min-width: 758px) {
    .services__image {
        max-height: 65px;
        height: 100%;
        max-width: 50px;
    }

    .services__image:nth-child(3) {
        max-width: 30px;
    }
}
