.map__undertaker {
    margin-top: 42px;
    display: flex;
    max-width: 842px;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .map__undertaker {
        flex-direction: column;
        max-width: 320px;
    }
}