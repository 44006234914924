.footer {
    margin: 84px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.footer__undertaker {
    font-family: "Montserrat-Regular", Helvetica, sans-serif;
    margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    max-width: 365px;
    letter-spacing: 1.26px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .footer {
        margin-top: 44px;
        max-width: 320px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .footer__undertaker {
        margin: 15px auto;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }

    .footer__main_undertaker {
        text-align: center;
    }
}

