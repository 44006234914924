.info__title_below {
    padding: 0;
    color: #3C3C3C;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 66.667% */
    letter-spacing: 2.1px;
    text-align: center;
    margin: 100px auto 20px;
}

.info__title_below_second {
    margin-top: 30px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__title_below {
        font-size: 27px;
        line-height: 24px;
        margin: 20px auto 10px;
        max-width: 300px;
    }

    .info__title_below_second {
        margin-top: 0;
    }
}