.footer__text_number {
    padding: 0;
    max-width: 250px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
}

.footer__text-number {
    color: #333333;
    font-family: "Montserrat-Regular", Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 500;
    left: 0;
    letter-spacing: 1.26px;
    line-height: 23px;
    text-decoration: none;
    margin: 10px;
}

@media screen and (max-width: 758px){
    .footer__text-number {
        margin: 20px 0 0 0;
    }
}