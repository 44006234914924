.header__logo {
    max-width: 155px;
    max-height: 53px;
    width: 100%;
    margin: 0;
    padding: 26px 21px 19px 27px;

}

@media screen and (min-width: 320px) and (max-width: 758px){
    .header__logo {
        max-width: 170px;
        max-height: 170px;
        padding: 0;
        margin-right: 7px;
    }
}
