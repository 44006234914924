.info__block {
    margin: 0;
    padding: 0;
    display: flex;
}

.info__block_third {
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .info__block {
        max-width: 320px;
    }
}