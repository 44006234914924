.info__title_higher {
    margin: 0 auto 0;
    padding: 0;
    color: #3C3C3C;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 52.632% */
    letter-spacing: 2.1px;
    text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__title_higher {
        text-align: center;

        max-width: 300px;
    }
}