.info__main_higher {
    color: #3C3C3C;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: justify;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__main_higher {
        margin: 20px auto 0 auto;
        font-size: 19px;
        line-height: 26px;
        text-align: justify;
    }
}