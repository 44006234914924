.services__border {
    max-width: 557px;
    object-fit: fill;
    overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services__border {
        display: none;
    }
}