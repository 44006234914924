.services__list_content {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 83.333% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

.services__list_content:nth-child(n+2) {
    margin-top: 20px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services__list_content {
        font-size: 16px;
    }
}
