.services {
    max-width: 1200px;
    width: 100vw;
    display: flex;
    margin: 100px 0 0 0;
    padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services {
        max-width: 320px;
    }
}
