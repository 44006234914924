.info__text_below {
    margin: 0 auto;
    padding: 0 76px 0 50px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__text_below {
        max-width: 320px;
        padding: 0;
    }
}