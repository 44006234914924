.map__iframe {
    position: relative;
    border: black 1px solid;
    width: 838px;
    height: 679px;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .map__iframe {
        width: 320px;
        height: 270px;
    }
}