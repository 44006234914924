.services__button {
    margin: 0;
    padding: 0;
    width: 34px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    transition: 1s;
}

.services__button_active {
    transition: 1s;
    transform: rotate(180deg);
}