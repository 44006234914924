.info__main_undertaker {
    color: #3C3C3C;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 700px;
    text-align: center;
    margin-top: 10px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__main_undertaker {
        font-size: 19px;
        line-height: 20px;
        width: 75%;
    }
}