.header__list_element {
    color: black;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    max-width: 228px;
    font-family: Montserrat, sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 71.429% */
    letter-spacing: 1.12px;
    text-decoration: none;
    display: flex;
    outline: none;
    box-shadow: none;
}

.header__list_element:hover {
    cursor: pointer;
    color: #C3ADAD;
}



@media screen and (min-width: 320px) and (max-width: 758px) {
    .header__list_element {
        font-size: 26px;
        font-weight: 500;
        margin: 1px 0 1px 5px;
    }
}

@media screen and (min-width: 1201px) {
    .header__list_element {
        font-size: 35px;
        font-weight: 400;
        line-height: 30px;
    }
}