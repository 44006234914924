.services__main {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .services__main {
        margin-top: 100px;
    }
}
