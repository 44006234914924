.map__title {
    color: #000000;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 40px;
    font-weight: 400;
    left: 0;
    letter-spacing: 1.12px;
    line-height: 20px;
    margin: 50px 0 23px;
    padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .map__title {
        font-size: 28px;
    }
}