.map__button {
    border: none;
    background: none;
    display: flex;
}

.map__button:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.3s ease;
}