.header__number {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    max-width: 230px;
    width: 100%;
}

.header__numbers {
    margin: 5px auto;
    font-size: 22px;
    font-weight: 500;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    line-height: 20px; /* 83.333% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .header__number {
        display: none;
    }
}