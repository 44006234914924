.info__button {
    margin: 10px 0 0 0;
    padding: 0;
    background: none;
    border: none;
    color: cornflowerblue;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 87.5% */
}

.info__button:hover {
    cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__button {
        margin: 110px auto;
        color: white;
    }
}