.examples__close {
    z-index: 7;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../../images/Close.svg");
    width: 40px;
    height: 40px;
    margin: -60px auto;
}

.examples__close:hover {
    cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .examples__close {
        margin: 30px auto;
    }
}