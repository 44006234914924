.info__text_higher {
    padding: 68px 76px 88px 50px;
    max-width: 500px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__text_higher {
        margin: 0 auto;
        padding: 44px 26px 25px 15px;
        display: flex;
        flex-direction: column;
    }
}