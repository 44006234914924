.info__swiper {
    max-width: 600px;
    width: 100%;
    cursor: grab;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .info__swiper {
        display: none;
    }
}