.map {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .map {
        max-width: 320px;
        margin-top: 10px;
    }
}