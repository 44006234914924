
.header {
    background-color: white;
    max-width: 1200px;
    width: 100%;
    height: 100px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Montserrat, sans-serif;
    position: fixed;
    z-index: 5;
    border-bottom: 1px solid #C3ADAD;
}

header .nav-btn {
    width: 60px;
    height: 60px;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    visibility: hidden;
    opacity: 0;
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .header {
        left: 0;
        width: 100vw;
        height: 200px;
    }
}

@media screen and (min-width: 1201px){
    .header {
        left: 0;
        width: 100vw;
        max-width: inherit;
    }
}