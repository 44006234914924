.info__main {
    color: #3C3C3C;
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 115.385% */
    margin: 28px auto 11px auto;
}

.info__main_record {
    margin: 0;
    padding: 0;
    max-width: 600px;
    text-align: center;
}

.info__enter {
    text-align: center;
}

.info__main_txt {
    display: flex;
    margin: 25px auto 0;
    padding: 0;
    align-items: flex-start;
}

.info__main-link {
    margin-right: 4px;
    color: cornflowerblue;
    cursor: pointer;
}

.info__txt {
    margin: 0;
    padding: 0;
    line-height: 35px;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info__main {
        font-size: 19px;
        margin: 11px auto 11px auto;
        line-height: 29px;
        max-width: 300px;
        text-align: justify;
    }

    .info__enter {
        text-align: center;
    }

    .info__main_record {
        text-align: center;
    }

    .info__main_txt {
        display: flex;
        margin: 0 auto 20px;
        padding: 0;
        align-items: flex-start;
    }

    .info__txt {
        margin: 0;
        padding: 0;
        text-align: center;
    }
}