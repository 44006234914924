.info {
    background-color: #F8F8F8;
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 790px 1000px 470px;
}

.info__wait {
    margin: 15px 0 15px 0;
    padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 758px){
    .info {
        max-width: 320px;
        grid-template-rows: 550px 750px;
    }
}