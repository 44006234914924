.services__list {
    margin: 64px 0 0 96px;
    padding: 0;
    list-style: none;
}

@media screen and (min-width: 320px) and (max-width: 758px) {
    .services__list {
        padding: 0 10px 0 10px;
        margin: 64px 0 0 0;
        max-width: 320px;
    }
}
